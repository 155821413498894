import { ensureShopifyGid, shopifyNumericIdFromGid } from "@smartrr/shared/utils/ensureShopifyGid";

export const numericIdsToFullShopifyIds = (
  ids: (number | string)[],
  type: "Product" | "ProductVariant"
): string[] => {
  return ids.map(id => ensureShopifyGid(type, id));
};

export const fullShopifyIdsToNumericIds = (ids: string[]): string[] => {
  return ids.map(id => shopifyNumericIdFromGid(id));
};
