import {
  ISellingPlanGroupCreate,
  ISellingPlanGroupUpdate,
  ISmartrrSellingPlanGroup,
  SellingPlanGroupApiResponse,
  SellingPlanGroupValidationErrors,
  sellingPlanGroupCreateSchema,
  sellingPlanGroupUpdateSchema,
} from "@smartrr/shared/entities/SellingPlanGroup";
import { WithResult } from "@smartrr/shared/types";
import { captureException } from "@smartrr/shared/utils/captureException";
import { StateCreator, create } from "zustand";
import { devtools } from "zustand/middleware";

import { fullShopifyIdsToNumericIds, numericIdsToFullShopifyIds } from "@vendor-app/utils/numericIdsMapper";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { delay } from "@smartrr/shared/utils/delay";
import { handleValidationErrors } from "@smartrr/shared/utils/sellingPlans";

interface ISellingPlanStoreLoadingTypes {
  areGroupsLoading: boolean;
  isGroupLoading: boolean;
}

interface ISellingPlanStore extends ISellingPlanStoreLoadingTypes {
  groups: ISmartrrSellingPlanGroup[];
  selectedGroup: SellingPlanGroupApiResponse | null;

  validationErrors: SellingPlanGroupValidationErrors | null;

  actions: {
    fetchSellingPlanGroups: (bustCache: boolean) => Promise<WithResult<ISmartrrSellingPlanGroup[]>>;
    fetchSellingPlanGroup: (id: string) => Promise<WithResult<SellingPlanGroupApiResponse>>;
    createSellingPlanGroup: (
      sellingPlanGroup: ISellingPlanGroupCreate
    ) => Promise<WithResult<SellingPlanGroupApiResponse>>;
    updateSellingPlanGroup: (
      id: string,
      sellingPlanGroup: ISellingPlanGroupUpdate & { shopifyId?: string | null }
    ) => Promise<WithResult<SellingPlanGroupApiResponse>>;
    deleteSellingPlanGroup: (id: string) => Promise<WithResult<{}>>;
    clearValidationErrors: () => void;
  };

  internal: {
    whileLoading<Type>(
      actionName: string,
      loadingType: keyof ISellingPlanStoreLoadingTypes,
      fn: () => Promise<Type>
    ): Promise<Type>;
  };
}

const sellingPlanStoreSlice: StateCreator<ISellingPlanStore, [["zustand/devtools", never]]> = (set, get) => ({
  groups: [],
  areGroupsLoading: true,
  isGroupLoading: true,
  selectedGroup: null,
  validationErrors: null,

  actions: {
    async fetchSellingPlanGroups(bustCache) {
      const ACTION_NAME = "fetchSellingPlanGroups";

      return await get().internal.whileLoading(ACTION_NAME, "areGroupsLoading", async () => {
        // When creating a new selling plan group, there is an indeterminate delay before
        // it appears in the GraphQL list operation. As a result, even without caching
        // the newly created selling plan group may not immediately show up in the list.
        // Waiting a few seconds increases the chance it will appear in the list.
        if (bustCache) {
          await delay(3000);
        }

        try {
          const data = await typedFrontendVendorApi.getReq("/selling-plan-group", {
            query: {
              cache: bustCache ? "false" : "true",
            },
          });

          if (data.type === "success") {
            set(
              () => ({
                groups: data.body,
              }),
              false,
              ACTION_NAME
            );
            return {
              result: "success",
              data: data.body,
            };
          }

          return {
            result: "failure",
            message: data.message,
          };
        } catch {
          return {
            result: "failure",
            message: "Failed to fetch selling plan groups",
          };
        }
      });
    },

    async fetchSellingPlanGroup(id: string) {
      const ACTION_NAME = "fetchSellingPlanGroup";

      return await get().internal.whileLoading(ACTION_NAME, "isGroupLoading", async () => {
        try {
          const data = await typedFrontendVendorApi.getReq("/selling-plan-groups/:id", {
            params: {
              id,
            },
          });

          if (data.type === "success") {
            const productIds = numericIdsToFullShopifyIds(data.body.productIds, "Product");
            const productVariantIds = numericIdsToFullShopifyIds(data.body.productVariantIds, "ProductVariant");

            set(
              () => ({
                selectedGroup: { ...data.body, productIds, productVariantIds },
                validationErrors: null,
              }),
              false,
              ACTION_NAME
            );

            return {
              result: "success",
              data: { ...data.body, productIds, productVariantIds },
            };
          }

          return {
            result: "failure",
            message: data.message,
          };
        } catch {
          return {
            result: "failure",
            message: "Failed to fetch selling plan group",
          };
        }
      });
    },

    async createSellingPlanGroup(sellingPlanGroup: ISellingPlanGroupCreate) {
      const ACTION_NAME = "createSellingPlanGroup";

      return await get().internal.whileLoading(ACTION_NAME, "isGroupLoading", async () => {
        const reqBody = {
          ...sellingPlanGroup,
          productIds: fullShopifyIdsToNumericIds(sellingPlanGroup.productIds ?? []),
          productVariantIds: fullShopifyIdsToNumericIds(sellingPlanGroup.productVariantIds ?? []),
        };

        const createValidation = sellingPlanGroupCreateSchema.safeParse(reqBody);
        if (!createValidation.success) {
          set(
            () => ({
              validationErrors: createValidation.error.format(),
            }),
            false,
            ACTION_NAME
          );
          const validationErrors = get().validationErrors;
          const errorMessage = handleValidationErrors(validationErrors);
          return {
            result: "failure",
            message: errorMessage ?? "One or more fields have an error. Please correct and try again.",
          };
        }

        set(
          () => ({
            validationErrors: null,
          }),
          false,
          ACTION_NAME
        );

        try {
          const createdSellingPlanGroupRes = await typedFrontendVendorApi.postReq("/selling-plan-groups", {
            reqBody,
          });

          if (createdSellingPlanGroupRes.type === "success") {
            const productIds = numericIdsToFullShopifyIds(createdSellingPlanGroupRes.body.productIds, "Product");
            const productVariantIds = numericIdsToFullShopifyIds(
              createdSellingPlanGroupRes.body.productVariantIds,
              "ProductVariant"
            );

            set(
              () => ({
                selectedGroup: { ...createdSellingPlanGroupRes.body, productIds, productVariantIds },
              }),
              false,
              ACTION_NAME
            );

            return {
              result: "success",
              data: { ...createdSellingPlanGroupRes.body, productIds, productVariantIds },
            };
          }

          return {
            result: "failure",
            message: createdSellingPlanGroupRes.message,
          };
        } catch (error) {
          captureException("Failed to create selling plan group", error);

          return {
            result: "failure",
            message: "Failed to create selling plan group",
          };
        }
      });
    },

    async updateSellingPlanGroup(id: string, sellingPlanGroup: ISellingPlanGroupUpdate) {
      const ACTION_NAME = "updateSellingPlanGroup";

      return await get().internal.whileLoading(ACTION_NAME, "isGroupLoading", async () => {
        const reqBody = {
          ...sellingPlanGroup,
          productIds: fullShopifyIdsToNumericIds(sellingPlanGroup.productIds ?? []),
          productVariantIds: fullShopifyIdsToNumericIds(sellingPlanGroup.productVariantIds ?? []),
        };

        const updateValidation = sellingPlanGroupUpdateSchema.safeParse(reqBody);
        if (!updateValidation.success) {
          set(() => ({
            validationErrors: updateValidation.error.format(),
          }));
          const validationErrors = get().validationErrors;
          const errorMessage = handleValidationErrors(validationErrors);
          return {
            result: "failure",
            message: errorMessage ?? "One or more fields have an error. Please correct and try again.",
          };
        }

        set(
          () => ({
            validationErrors: null,
          }),
          false,
          ACTION_NAME
        );

        try {
          const updatedSellingPlanGroupRes = await typedFrontendVendorApi.putReq("/selling-plan-groups/:id", {
            params: { id },
            reqBody,
          });

          if (updatedSellingPlanGroupRes.type === "success") {
            const productIds = numericIdsToFullShopifyIds(updatedSellingPlanGroupRes.body.productIds, "Product");
            const productVariantIds = numericIdsToFullShopifyIds(
              updatedSellingPlanGroupRes.body.productVariantIds,
              "ProductVariant"
            );

            set(
              () => ({
                selectedGroup: { ...updatedSellingPlanGroupRes.body, productIds, productVariantIds },
              }),
              false,
              ACTION_NAME
            );

            return {
              result: "success",
              data: { ...updatedSellingPlanGroupRes.body, productIds, productVariantIds },
            };
          }

          return {
            result: "failure",
            message: updatedSellingPlanGroupRes.message,
          };
        } catch (error) {
          captureException("Failed to update selling plan group", error);

          return {
            result: "failure",
            message: "Failed to update selling plan group",
          };
        }
      });
    },
    async deleteSellingPlanGroup(id: string) {
      const ACTION_NAME = "deleteSellingPlanGroup";

      return await get().internal.whileLoading(ACTION_NAME, "isGroupLoading", async () => {
        try {
          const deletedSellingPlanGroupRes = await typedFrontendVendorApi.deleteReq("/selling-plan-groups/:id", {
            params: { id },
          });

          if (deletedSellingPlanGroupRes.type === "success") {
            set(
              () => ({
                selectedGroup: undefined,
              }),
              false,
              ACTION_NAME
            );

            return {
              result: "success",
              data: {},
            };
          }

          return {
            result: "failure",
            message: deletedSellingPlanGroupRes.message,
          };
        } catch (error) {
          captureException("Failed to delete selling plan group", error);

          return {
            result: "failure",
            message: "Failed to delete selling plan group",
          };
        }
      });
    },
    clearValidationErrors() {
      set(() => ({
        validationErrors: null,
      }));
    },
  },

  internal: {
    async whileLoading<Type>(
      actionName: string,
      loadingType: keyof ISellingPlanStoreLoadingTypes,
      fn: () => Promise<Type>
    ): Promise<Type> {
      set(
        {
          [loadingType]: true,
        },
        false,
        actionName
      );

      const result = await fn();

      set(
        {
          [loadingType]: false,
        },
        false,
        actionName
      );

      return result;
    },
  },
});

const useSellingPlanGroupStore = create<ISellingPlanStore>()(
  devtools(sellingPlanStoreSlice, { name: "SellingPlansStore" })
);

export const SellingPlanGroupStoreAccess = {
  useActions: () => useSellingPlanGroupStore(state => state.actions),
  useIsGroupLoading: () => useSellingPlanGroupStore(state => state.isGroupLoading),
  useAreGroupsLoading: () => useSellingPlanGroupStore(state => state.areGroupsLoading),
  useGroups: () => useSellingPlanGroupStore(state => state.groups),
  useSelectedGroup: () => useSellingPlanGroupStore(state => state.selectedGroup),
  useValidationErrors: () => useSellingPlanGroupStore(state => state.validationErrors),

  testing: {
    store() {
      return useSellingPlanGroupStore;
    },
    initialState: useSellingPlanGroupStore.getState(),
    state: useSellingPlanGroupStore.getState,
    actions: useSellingPlanGroupStore.getState().actions,
    reset() {
      useSellingPlanGroupStore.setState(this.initialState);
    },
  },
};
